import { useAppDispatch, useAppSelector } from '@/hooks'
import { referralsCollection } from '@/reducers/referrals'
import { transactionsCollection } from '@/reducers/transactions'
import { utilityProgramEnrollmentCollection } from '@/reducers/utilityProgramEnrollments'
import { RequestStatus } from '@/request/types'
import { useEffect, useState } from 'react'
import { transactionsRedemptionCollection } from '@/reducers/transactionsRedemption'
import { Transaction } from '@/types/transaction'
import { logEvent } from '@/logging'

export type ViewModelProps = ReturnType<typeof useViewModel>

export function useViewModel() {
  const DEFAULT_POINTS_TO_REDEEM = 500

  // Hooks
  const dispatch = useAppDispatch()
  const [transactionToRedeem, setTransactionToRedeem] =
    useState<Transaction | null>(null)

  // State
  const [tremendousModalOpen, setTremendousModalOpen] = useState(false)
  const [pointsRedemptionModalOpen, setPointsRedemptionModalOpen] =
    useState(false)
  const [pointsToRedeem, setPointsToRedeem] = useState<number>(
    DEFAULT_POINTS_TO_REDEEM,
  )
  const [rewardError, setRewardError] = useState<string | null>(null)
  const [pointsRedeemed, setPointsRedeemed] = useState<number | null>(null)
  const [showApprovalWarning, setShowApprovalWarning] = useState(false)

  // Fetches
  const { data: utilityProgramEnrollments, status } =
    utilityProgramEnrollmentCollection.useFetch()
  const { data: referrals, status: referralFetchStatus } =
    referralsCollection.useFetch()
  const { data: transactions, status: transactionFetchStatus } =
    transactionsCollection.useFetch()

  // Selectors
  const code = useAppSelector(
    (state) => state.user.user?.profile?.referral_code,
  )

  // Functions
  const redeem = async () => {
    dispatch(
      transactionsRedemptionCollection.actions.create({
        amount: pointsToRedeem,
      }),
    ).then((res: { payload: Transaction; error: boolean }) => {
      if (res.error) {
        setRewardError(
          'There was an error redeeming your points. Please try again.',
        )
      } else {
        logEvent('rewards_redeemPoints', {
          metadata: { points: pointsToRedeem },
        })
        openTremendousModal(res.payload, true)
        dispatch(transactionsCollection.actions.fetch())
      }
      setPointsRedemptionModalOpen(false)
    })
  }

  const closeCongratsModal = () => {
    setPointsRedeemed(null)
    setPointsToRedeem(DEFAULT_POINTS_TO_REDEEM)
  }

  const completeTransaction = (transaction_id: number) => {
    if (!transactionToRedeem) return
    dispatch(
      transactionsCollection.actions.update(transaction_id, {
        status: 'completed',
      }),
    ).then((res: { payload: Transaction }) => {
      logEvent('rewards_tremendous_complete', {
        metadata: { points: Math.abs(res.payload.points) },
      })
      setPointsRedeemed(Math.abs(res.payload.points))
      dispatch(transactionsCollection.actions.fetch())
    })
  }

  const openTremendousModal = (
    transaction?: Transaction,
    showApprovalWarning?: boolean,
  ) => {
    setTremendousModalOpen(true)
    setShowApprovalWarning(showApprovalWarning ?? false)
    if (transaction) {
      setTransactionToRedeem(transaction)
    }
  }

  const closeTremendousModal = () => {
    setTremendousModalOpen(false)
    setTransactionToRedeem(null)
  }

  // Mounting Actions
  useEffect(() => {
    // invalidate fetches to ensure they get reloaded on mount
    dispatch(utilityProgramEnrollmentCollection.actions.invalidate())
    dispatch(referralsCollection.actions.invalidate())
    dispatch(transactionsCollection.actions.invalidate())
  }, [])

  // Constants
  const referralPoints =
    utilityProgramEnrollments?.[0]?.utility_program?.referral_points
  const referralLink = `https://optiwatt.com/?referral_code=${code}`
  const availablePoints = transactions?.[0]?.total_points?.points__sum || 0
  const pendingTransactions = transactions[0]?.results.filter(
    (transaction) => transaction.status.toLowerCase() === 'pending',
  )
  const pendingReferrals = referrals?.filter(
    (referral) =>
      referral.status.toLowerCase() === 'pending' &&
      referral.referred_name !== 'DELETED',
  )
  const completedTransactions = transactions[0]?.results.filter(
    (transaction) => transaction.status.toLowerCase() === 'completed',
  )
  const completedReferrals = referrals?.filter(
    (referral) => referral.status.toLowerCase() === 'completed',
  )

  const pageLoading =
    status === RequestStatus.Loading ||
    referralFetchStatus === RequestStatus.Loading ||
    transactionFetchStatus === RequestStatus.Loading

  return {
    pageLoading,
    referralPoints,
    referralLink,
    code,
    availablePoints,
    transactions,
    pendingTransactions,
    pendingReferrals,
    completedTransactions,
    completedReferrals,
    pointsRedemptionModalOpen,
    setPointsRedemptionModalOpen,
    referralFetchStatus,
    transactionFetchStatus,
    transactionToRedeem,
    redeem,
    rewardError,
    setRewardError,
    openTremendousModal,
    closeTremendousModal,
    pointsToRedeem,
    setPointsToRedeem,
    completeTransaction,
    tremendousModalOpen,
    pointsRedeemed,
    closeCongratsModal,
    showApprovalWarning,
  }
}

import Modal, { Props as ModalProps } from '@/components/Modal/Modal'
import { LoadingAnimation, Text } from '@/components'
import { useEffect, useState } from 'react'
import ToggleButton from '@/components/ToggleButton'
import { ConfigurableButton } from '@/app/components'
import * as Feather from 'react-feather'
import { useWizard, Wizard } from 'react-use-wizard'
import { ViewModelProps } from '../../useViewModel'

type RedemptionStepProps = {
  onClose?: () => void
  totalPoints: number
  remainingPoints: number
  pointsToRedeem: number
  setPointsToRedeem: React.Dispatch<React.SetStateAction<number>>
  redemptionType: 'all' | 'custom' | null
  setRedemptionType: React.Dispatch<
    React.SetStateAction<'all' | 'custom' | null>
  >
  onRedeem?: (pointsToRedeem: number) => void
}

const SetRedemptionDetails = (props: RedemptionStepProps) => {
  const { nextStep } = useWizard()
  const {
    totalPoints,
    remainingPoints,
    pointsToRedeem,
    setPointsToRedeem,
    redemptionType,
    setRedemptionType,
  } = props
  const redemptionOptions = [
    {
      value: 'all',
      label: (
        <div className="flex flex-col w-full items-start">
          <Text variant="h3">Redeem All Points</Text>
          <Text variant="h3-subheader" className="font-medium">
            {totalPoints.toLocaleString()} pts = $
            {(totalPoints / 100).toLocaleString()}.00
          </Text>
        </div>
      ),
    },
    {
      value: 'custom',
      label: (
        <div className="flex flex-col w-full items-start">
          <Text variant="h3">Custom Amount</Text>
          <Text variant="h3-subheader" className="font-medium">
            {(redemptionType === 'all' ? 500 : pointsToRedeem).toLocaleString()}{' '}
            pts = $
            {(redemptionType === 'all'
              ? 5
              : (pointsToRedeem / 100) * 1
            ).toLocaleString()}
            .00
          </Text>
        </div>
      ),
    },
  ]

  const handleMouseDown = (
    e: React.MouseEvent<HTMLButtonElement>,
    action: 'minus' | 'plus',
  ) => {
    if (e.button === 2 || e.ctrlKey) {
      e.preventDefault()
      return
    }
    if (action === 'minus') {
      setPointsToRedeem((prev) => (prev > 500 ? prev - 100 : prev))
      const interval = setInterval(() => {
        setPointsToRedeem((prev) => (prev > 500 ? prev - 100 : prev))
      }, 100)
      document.addEventListener('pointerup', () => {
        clearInterval(interval)
      })
    }
    if (action === 'plus') {
      setPointsToRedeem((prev) => (prev < totalPoints ? prev + 100 : prev))
      const interval = setInterval(() => {
        setPointsToRedeem((prev) => (prev < totalPoints ? prev + 100 : prev))
      }, 100)
      document.addEventListener('pointerup', () => {
        clearInterval(interval)
      })
    }
  }

  return (
    <>
      <div
        id="cashBack-section"
        className="w-full flex flex-col justify-around gap-4"
      >
        <Text variant="h2" className="w-full !text-left">
          Cash Back
        </Text>
        <ToggleButton.ToggleButtonGroup
          value={redemptionType}
          onChange={(value) => {
            setRedemptionType(value as typeof redemptionType)
            if (value === 'all') setPointsToRedeem(totalPoints)
            else setPointsToRedeem(500)
          }}
          className="flex-col"
        >
          {redemptionOptions.map((option, i) => (
            <ToggleButton.ToggleButton
              key={option.value}
              id={option.value}
              value={option.value}
              resetStyle={true}
              className={`flex flex-row justify-between items-center w-full gap-4`}
            >
              {option.label}
              {redemptionType === option.value ? (
                <Feather.CheckCircle className="w-7 h-7 text-themed-success" />
              ) : (
                <Feather.Circle className="w-7 h-7 text-themed-base-900" />
              )}
            </ToggleButton.ToggleButton>
          ))}
        </ToggleButton.ToggleButtonGroup>
      </div>
      {redemptionType && (
        <div
          id="info-section"
          className="w-full flex flex-col items-center p-4 border border-themed-base-300 border-solid rounded-2xl"
        >
          <div className="w-full flex items-center justify-between">
            <Text variant="body1">Current Point Balance</Text>
            <Text variant="body2">{totalPoints.toLocaleString()} pts</Text>
          </div>
          <div className="w-full flex items-center h-10 justify-between">
            <Text variant="body1">Points to be Redeemed</Text>
            {redemptionType === 'custom' && (
              <ConfigurableButton
                id="redemption-minus"
                variant="icon"
                onSelect={(e) => e.preventDefault()}
                onPointerDown={(e) => handleMouseDown(e, 'minus')}
                disabled={pointsToRedeem <= 500}
                className={`!h-7 !aspect-square !rounded-lg !bg-themed-base-200 p-0`}
              >
                -
              </ConfigurableButton>
            )}
            <Text variant="body2">{pointsToRedeem.toLocaleString()} pts</Text>
            {redemptionType === 'custom' && (
              <ConfigurableButton
                id="redemption-minus"
                variant="icon"
                onSelect={(e) => e.preventDefault()}
                onPointerDown={(e) => handleMouseDown(e, 'plus')}
                disabled={pointsToRedeem >= totalPoints}
                className={`!h-7 !aspect-square !rounded-lg !bg-themed-base-200 p-0`}
              >
                +
              </ConfigurableButton>
            )}
          </div>
          <div className="w-full flex items-center justify-between">
            <Text variant="body1">Remaining Points</Text>
            <Text variant="body2">{remainingPoints.toLocaleString()} pts</Text>
          </div>
        </div>
      )}
      <div
        id="submit-section"
        className="w-full flex flex-row gap-4 items-center justify-evenly mt-auto"
      >
        <ConfigurableButton
          id="redemption-cancel"
          variant="primary-subtle"
          onClick={props.onClose}
        >
          Cancel
        </ConfigurableButton>
        <ConfigurableButton
          id="redemption-next"
          variant="primary"
          onClick={nextStep}
          disabled={!redemptionType}
        >
          Next
        </ConfigurableButton>
      </div>
    </>
  )
}

const ConfirmRedemption = (props: RedemptionStepProps) => {
  const { previousStep } = useWizard()
  const { totalPoints, remainingPoints, pointsToRedeem } = props
  const [confirmed, setConfirmed] = useState(false)

  const confirm = () => {
    setConfirmed(true)
    props.onRedeem?.(pointsToRedeem)
  }

  return (
    <>
      <div
        id="info-section"
        className="w-full flex flex-col items-center p-4 border border-themed-base-300 border-solid rounded-2xl"
      >
        {confirmed && <LoadingAnimation type="falling" className="absolute" />}
        <div className="w-full flex items-center justify-between">
          <Text variant="body1">Current Point Balance</Text>
          <Text variant="body2">{totalPoints.toLocaleString()} pts</Text>
        </div>
        <div className="w-full flex items-center h-10 justify-between">
          <Text variant="body1">Points to be Redeemed</Text>
          <Text variant="body2">{pointsToRedeem.toLocaleString()} pts</Text>
        </div>
        <div className="w-full flex items-center justify-between">
          <Text variant="body1">Remaining Points</Text>
          <Text variant="body2">{remainingPoints.toLocaleString()} pts</Text>
        </div>
      </div>
      <div
        id="submit-section"
        className="w-full flex flex-row gap-4 items-center justify-evenly mt-auto"
      >
        <ConfigurableButton
          id="redemption-cancel"
          variant="primary-subtle"
          onClick={previousStep}
        >
          Back
        </ConfigurableButton>
        <ConfigurableButton
          id="redemption-confirm"
          variant="primary"
          onClick={confirm}
          disabled={confirmed}
        >
          {'Confirm'}
        </ConfigurableButton>
      </div>
    </>
  )
}

export default function CustomRedemptionModal(
  props: ModalProps & {
    onRedeem: RedemptionStepProps['onRedeem']
  } & ViewModelProps,
) {
  // ViewModel
  const { pointsToRedeem, setPointsToRedeem } = props
  useEffect(() => {
    if (!props.open) return
    setRedemptionType(null)
  }, [props.open])
  // State
  const [redemptionType, setRedemptionType] =
    useState<RedemptionStepProps['redemptionType']>(null)

  // Constants
  const totalPoints = props.availablePoints
  const remainingPoints = totalPoints - pointsToRedeem

  // Props
  const stepProps = {
    onClose: props.onClose,
    totalPoints,
    remainingPoints,
    pointsToRedeem,
    setPointsToRedeem,
    redemptionType,
    setRedemptionType,
    onRedeem: props.onRedeem,
  }
  return (
    <Modal {...props}>
      <div className="h-full md:!h-[600px] flex flex-col items-center justify-start gap-10 p-4 max-w-md">
        <div id="title-section" className="w-full flex flex-col gap-4">
          <Text variant="h2" className="w-full">
            Redeem Points
          </Text>
          <Text variant="h3-subheader" className="w-full font-medium">
            100 points is equal to $1. You can start redeeming points with 500
            or more.
          </Text>
        </div>
        <Wizard>
          <SetRedemptionDetails {...stepProps} />
          <ConfirmRedemption {...stepProps} />
        </Wizard>
      </div>
    </Modal>
  )
}

import { Card } from '@/components'
import * as Feather from 'react-feather'
import { Text } from '@/components'
import { AlertMessage, ConfigurableButton } from '@/app/components'
import { Transaction } from '@/types/transaction'
import { LoadingAnimation } from '@/components'
import CustomRedemptionModal from './components/redemptionModal'
import { ViewModelProps } from '../useViewModel'
import TremendousModal from './components/tremendousModal'
import { useEffect, useState } from 'react'
import TransactionDisplayModal from './components/transactionDisplayModal'
import ReminderModal from './components/reminderModal'
import { useAppDispatch } from '@/hooks'
import { referralsCollection } from '@/reducers/referrals'
import { Referral } from '@/types/referral'
import { fetchAuthenticated } from '@/utils/fetch'
import CongratsModal from './components/congratsModal'
import Tabs from '@/components/Tabs'

type RewardRowProps = {
  onClick?: () => void
  icon: JSX.Element
  label: string
  label2: JSX.Element
  description: string
}

const RewardRow = ({ ...props }: RewardRowProps) => {
  const { icon, label, label2, description, onClick } = props

  return (
    <div
      className="w-full flex flex-row items-center justify-between py-4"
      onClick={onClick}
    >
      {icon}
      <div className="flex flex-col grow items-start px-4">
        <Text variant="subheader">{label}</Text>
        <Text variant="body2" className="font-bold">
          {description}
        </Text>
      </div>
      {label2}
    </div>
  )
}

const PendingRows = (props: ViewModelProps) => {
  if (!props.pendingTransactions.length && !props.pendingReferrals.length)
    return null
  const [reminderModalOpen, setReminderModalOpen] = useState(false)
  const dispatch = useAppDispatch()
  const sendReminder = (referral: Referral) => {
    if (!referral.can_remind) return
    fetchAuthenticated(`referrals/${referral.id}/remind`, {
      method: 'PATCH',
    }).then((res) => {
      setReminderModalOpen(true)
    })
  }

  const onClose = () => {
    dispatch(referralsCollection.actions.fetch())
    setReminderModalOpen(false)
  }

  useEffect(() => {
    props.setRewardError(null)
    return () => {
      props.setRewardError(null)
    }
  }, [])

  return (
    <div className="w-full flex justify-start flex-col">
      <Text variant="h2">{'Pending'}</Text>
      <div className="w-full flex flex-col items-center divide-y divide-solid divide-themed-base-300">
        {props.pendingReferrals.map((referral) => (
          <RewardRow
            icon={
              <div className="w-10 h-10 flex items-center justify-center bg-[#F7EDFF] p-2 rounded-2xl">
                <Text variant="h3" className="text-themed-primary">
                  {referral.referred_name[0]}
                </Text>
              </div>
            }
            label={referral.referred_name}
            description={`Needs to connect their Vehicle`}
            key={referral.id}
            onClick={() => sendReminder(referral)}
            label2={
              <Text
                variant="link"
                className={`${
                  referral.can_remind
                    ? '!text-themed-neutral'
                    : '!text-themed-base-300'
                } !text-h3-subheader !font-medium !cursor-pointer`}
                onClick={(e) => {
                  e?.stopPropagation()
                  sendReminder(referral)
                }}
              >
                {'Remind'}
              </Text>
            }
          />
        ))}
        {props.pendingTransactions.map((transaction) => (
          <RewardRow
            icon={
              <Feather.DollarSign className="w-10 h-10 text-themed-orange-300 bg-[#FDF6E2] p-2.5 rounded-2xl" />
            }
            label={'Reward Redemption'}
            description={`(${transaction.points} pts)`}
            key={transaction.id}
            onClick={() => {
              props.openTremendousModal(transaction)
            }}
            label2={
              <Text
                variant="link"
                onClick={(e) => {
                  e?.stopPropagation()
                  props.openTremendousModal(transaction)
                }}
                className="!text-h3-subheader !cursor-pointer"
              >
                {'Finish'}
              </Text>
            }
          />
        ))}
      </div>
      <ReminderModal open={reminderModalOpen} onClose={onClose} />
    </div>
  )
}

const CompletedTransactions = (props: ViewModelProps) => {
  const [loadedTransactions, setLoadedTransactions] = useState(5)
  const [transactionToDisplay, setTransactionToDisplay] =
    useState<Transaction | null>(null)
  const loadMore = () => setLoadedTransactions((prev) => prev + 10)

  if (!props.completedTransactions.length) return null
  const completedTransactions = props.completedTransactions.slice(
    0,
    loadedTransactions,
  )

  const getProps = (transaction: Transaction) => {
    switch (transaction.type) {
      case 'referral':
        return {
          icon: (
            <Feather.Gift className="w-10 h-10 text-themed-primary bg-[#F7EDFF] p-2.5 rounded-2xl" />
          ),
          label: 'Inviation Bonus',
          description: 'For inviting a friend',
        }
      case 'redeem':
        return {
          icon: (
            <Feather.DollarSign className="w-10 h-10 text-themed-success bg-[#E5FBEC] p-2.5 rounded-2xl" />
          ),
          label: 'Reward Redemption',
          description: 'Complete',
        }
      case 'program_referral':
        return {
          icon: (
            <Feather.Gift className="w-10 h-10 text-themed-primary bg-[#F7EDFF] p-2.5 rounded-2xl" />
          ),
          label: 'Program Referral',
          description: 'For inviting a friend to a utility program',
        }
      case 'reward_program':
        return {
          icon: (
            <Feather.Gift className="w-10 h-10 text-themed-primary bg-[#F7EDFF] p-2.5 rounded-2xl" />
          ),
          label: 'Program Reward',
          description: 'For participating in a utility program',
        }
      case 'reward_program_signup':
        return {
          icon: (
            <Feather.Gift className="w-10 h-10 text-themed-primary bg-[#F7EDFF] p-2.5 rounded-2xl" />
          ),
          label: 'Program Sign-Up',
          description: 'For joining a utility program',
        }
      default:
        return {}
    }
  }

  return (
    <div className="w-full flex justify-start flex-col">
      <Text variant="h2">{'Completed'}</Text>
      <div className="w-full flex flex-col items-center divide-y divide-solid divide-themed-base-300">
        {completedTransactions?.map((transaction) => (
          <RewardRow
            icon={
              <Feather.DollarSign className="w-10 h-10 text-themed-orange-300 bg-[#FDF6E2] p-2.5 rounded-2xl" />
            }
            label={'Reward Redemption'}
            description={`(${transaction.points} pts)`}
            key={transaction.id}
            onClick={() => setTransactionToDisplay(transaction)}
            label2={
              <Text variant="h3" className="whitespace-nowrap">
                {transaction.points < 0 ? '- ' : '+ '}
                {Math.abs(transaction.points).toLocaleString()}
              </Text>
            }
            {...getProps(transaction)}
          />
        ))}
        {loadedTransactions < props.completedTransactions.length && (
          <Text
            variant="link"
            onClick={loadMore}
            className="w-full text-center pt-5 text-h3"
          >
            Load More
          </Text>
        )}
      </div>
      <TransactionDisplayModal
        transaction={transactionToDisplay}
        onClose={() => setTransactionToDisplay(null)}
      />
    </div>
  )
}

export default function Activity(props: ViewModelProps) {
  return (
    <>
      <AlertMessage variant="error" show={!!props.rewardError}>
        {props.rewardError}
      </AlertMessage>
      <div className="flex flex-col justify-center w-full !h-full gap-6">
        <div className="flex flex-col items-center justify-center w-full gap-6">
          <Card className="w-full">
            <div className="w-full h-full flex flex-col items-center gap-12">
              <AlertMessage className="w-full" variant="info">
                {props.transactions.length
                  ? '100 points is equal to $1'
                  : `Start redeeming with 500 or more points`}
              </AlertMessage>
              <div
                id="points_display"
                className="flex flex-col items-center justify-center"
              >
                <div className="flex flex-col items-center">
                  {props.pageLoading ? (
                    <LoadingAnimation type="falling" />
                  ) : (
                    <>
                      <div className="flex flex-row items-center gap-2">
                        <Feather.Award className="w-5 h-5 text-themed-primary" />
                        <Text
                          variant="h1"
                          id="available-points"
                          data-availablepoints={props.availablePoints}
                        >
                          {props.availablePoints.toLocaleString()}
                        </Text>
                      </div>
                      <Text variant="subheader">Points Available</Text>
                    </>
                  )}
                </div>
              </div>
              <div
                id="button_container"
                className="flex flex-row justify-around gap-4 w-full"
              >
                <ConfigurableButton
                  id="redeem_button"
                  className="flex grow"
                  onClick={() => props.setPointsRedemptionModalOpen(true)}
                >
                  <div className="flex flex-row gap-2 items-center">
                    <Feather.Award className="w-4 h-4" />
                    <Text variant="subheader" className="text-themed-white">
                      Redeem
                    </Text>
                  </div>
                </ConfigurableButton>
                <ConfigurableButton
                  id="earn_button"
                  variant="primary-subtle"
                  className="flex grow"
                  onClick={() => Tabs.setActiveTabIndex('reward-tabs', 0)}
                >
                  <div className="flex flex-row gap-2 items-center">
                    <Feather.Zap className="w-4 h-4" />
                    <Text variant="subheader" className="text-themed-primary">
                      Earn
                    </Text>
                  </div>
                </ConfigurableButton>
              </div>
            </div>
          </Card>
        </div>
        {!!props.transactions.length && !!props.transactions[0].count && (
          <Card className="w-full">
            <div className="w-full flex flex-col items-center gap-8">
              {props.pageLoading && (
                <div className="w-full flex h-full items-center justify-center absolute">
                  <LoadingAnimation type="falling" />
                </div>
              )}
              <PendingRows {...props} />
              <CompletedTransactions {...props} />
            </div>
          </Card>
        )}
      </div>
      <CustomRedemptionModal
        className="md:!max-w-md !w-full !px-4 !py-0 !gap-4 !flex !flex-col !items-center !justify-center"
        id="reward-redemption-modal"
        open={props.pointsRedemptionModalOpen}
        onClose={() => props.setPointsRedemptionModalOpen(false)}
        onRedeem={props.redeem}
        fullscreen={window.screen.width < 768}
        {...props}
      />
      <TremendousModal {...props} />
      <CongratsModal
        {...props}
        open={!!props.pointsRedeemed}
        onClose={props.closeCongratsModal}
      />
    </>
  )
}

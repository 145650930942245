import { ConfigurableButton } from '@/app/components'
import { Modal, Text } from '@/components'
import * as Feather from 'react-feather'
import { ViewModelProps } from '../../useViewModel'
import * as confetti from 'canvas-confetti'
import { useEffect, useRef } from 'react'

export default function CongratsModal({
  open,
  onClose,
  ...props
}: {
  open: boolean
  onClose: () => void
} & ViewModelProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const launchConfetti = (
    canvasConfetti: confetti.CreateTypes | null = null,
    endTime: number,
    screenSize: 'sm' | 'lg' = 'sm',
  ) => {
    if (!canvasConfetti && canvasRef.current)
      canvasConfetti = confetti.create(canvasRef.current || undefined, {
        resize: true,
      })
    if (!canvasConfetti) return
    const colors = ['#af4bfb', '#ffffff']

    const config = {
      particleCount: screenSize === 'lg' ? 2 : 3,
      spread: screenSize === 'lg' ? 55 : 40,
      startVelocity: screenSize === 'lg' ? 40 : 80,
      colors: colors,
    }

    canvasConfetti({
      angle: 110,
      origin: { y: 1, x: 1 },
      ...config,
    })
    canvasConfetti({
      angle: 70,
      origin: { y: 1, x: 0 },
      ...config,
    })
    if (Date.now() < endTime) {
      requestAnimationFrame(() =>
        launchConfetti(canvasConfetti, endTime, screenSize),
      )
    }
  }

  useEffect(() => {
    if (open && canvasRef.current) {
      const screenSize = window.innerWidth < 768 ? 'sm' : 'lg'
      const endTime = Date.now() + 1500
      launchConfetti(null, endTime, screenSize)
    }
  }, [open, canvasRef.current])

  return (
    <Modal
      id="congratsModal"
      open={open}
      onClose={onClose}
      fullscreen
      hideCloseButton
      className="!bg-transparent !p-0"
    >
      <div className="flex flex-col h-full w-full items-center justify-center">
        <div className="relative flex flex-col items-center gap-8 pt-4 md:max-w-[400px] w-full max-md:h-full h-[600px] rounded-2xl">
          <canvas
            ref={canvasRef}
            className="!bg-themed-white absolute w-full top-0 rounded-2xl h-full z-[-1]"
          />
          <div className="h-full flex flex-col p-4">
            <div className="flex flex-col items-center gap-8 grow justify-center">
              <div className="flex flex-col items-center gap-4">
                <Text
                  variant="h1"
                  className="text-themed-primary"
                >{`Congrats!`}</Text>
                <Feather.Award className="w-20 h-20 text-themed-primary" />
              </div>
              <div className="px-8">
                <Text variant="h3" className="text-center">
                  {`You redeemed ${props.pointsToRedeem} points for $${
                    props.pointsToRedeem / 100
                  }! Your reward is on its way, check your email for confirmation.`}
                </Text>
              </div>
            </div>
            <div>
              <ConfigurableButton
                id="congratsModalButton"
                variant="primary-subtle"
                onClick={onClose}
              >
                {`Finish`}
              </ConfigurableButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

import { useState } from 'react'
import { Card } from '@/components'
import { Copy } from 'react-feather'
import { Text } from '@/components'
import { motion, AnimatePresence } from 'framer-motion'
import ReferAFriend from '../img/refer-a-friend.png'
import ShareComponent from './components/shareComponent'
import { ViewModelProps } from '../useViewModel'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import {
  CLIPBOARD_COPY_TYPE,
  postMessagetoMobileIfPresent,
} from '@/utils/messageUtils'
import { useAppSelector, useMobileVersionFlag } from '@/hooks'
import { selectProgramViewConfigFromAllSources } from '@/selectors'

interface CopyLinkProps {
  url: string
}

function useViewModel(props: ViewModelProps) {
  const { referralPoints, referralLink, code } = props
  const viewConfig = useAppSelector(selectProgramViewConfigFromAllSources)
  // $5 + max(referral_points/100)
  const amount = 5 + Math.max((referralPoints ?? 0) / 100, 0)
  const utilityAmount = referralPoints ? amount : 50

  const title =
    viewConfig?.rewardsInviteTitle === ''
      ? `Invite your friends and get up to $${amount}`
      : viewConfig?.rewardsInviteTitle
  const description =
    viewConfig?.rewardsInviteDescription === ''
      ? `Share the link with your friends below. Earn $5 when your friend
      signs up with Optiwatt and adds their vehicle. Earn up to $${utilityAmount}
      if they join an active utility program!`
      : viewConfig?.rewardsInviteDescription

  return {
    amount,
    referralLink,
    code,
    utilityAmount,
    title,
    description,
  }
}

function CopyLink({ url }: CopyLinkProps) {
  const [copied, setCopied] = useState(false)
  const versionCompatible = useMobileVersionFlag('webviewRewards')

  const handleClick = async () => {
    try {
      if (isNativeMobile() && versionCompatible) {
        postMessagetoMobileIfPresent(url, CLIPBOARD_COPY_TYPE)
      } else {
        await navigator.clipboard.writeText(url)
      }
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 3000) // Display "Copied!" for 3 seconds
    } catch (error) {
      console.error('Failed to copy link to clipboard:', error)
    }
  }

  return (
    <div className="flex items-center cursor-pointer" onClick={handleClick}>
      <AnimatePresence mode="popLayout">
        {copied ? (
          <motion.div
            key="copied"
            initial={{ y: 15, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -15, opacity: 0 }}
          >
            <Text variant="body3" className="text-themed-primary">
              Copied!
            </Text>
          </motion.div>
        ) : (
          <motion.div
            key="copyLink"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
            className="flex items-center min-w-[80px]"
          >
            <Copy className="w-4 h-4 mr-3 text-themed-base-900" />
            <Text variant="body3">Copy Link</Text>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default function Invite(props: ViewModelProps) {
  const { referralLink, code, title, description } = useViewModel(props)

  return (
    <div className="flex flex-col justify-center w-full">
      <div className="flex flex-col items-center justify-center w-full gap-8 xl:items-start xl:flex-row">
        <Card className="w-full">
          <div className="flex flex-col items-center justify-center">
            <img
              src={ReferAFriend}
              className="w-full max-w-[220px] aspect-[220/120] mx-20 mb-4"
            />
            <Text variant="subheader">{title}</Text>
            <Text variant="body4" className="px-8 py-4 text-center">
              {description}
            </Text>
            <div className="w-full px-0">
              <div className="w-full p-4 rounded-[20px] border-2 border-dashed border-themed-base-200 bg-themed-base-100 flex justify-between">
                <Text className="flex items-center">{code}</Text>
                <CopyLink url={referralLink} />
              </div>
              <ShareComponent />
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { ViewModelProps } from '../../useViewModel'
import { LoadingAnimation, Modal } from '@/components'
import Text from '@/components/Text/Text'
import Button from '@/components/Button/Button'
import { InfoIcon, MailIcon } from 'lucide-react'

export default function TremendousModal(props: ViewModelProps) {
  const [loading, setLoading] = useState(true)
  const [approvalModalOpen, setApprovalModalOpen] = useState(false)
  useEffect(() => {
    window.addEventListener('message', async (event) => {
      if (!event.origin.includes('tremendous.com')) return
      const data = JSON.parse(event.data) as {
        event: 'EXIT' | 'REDEEM' | 'ERROR'
      }
      switch (data.event) {
        case 'EXIT':
          props.closeTremendousModal()
          break
        case 'REDEEM':
          if (!props.transactionToRedeem) return
          props.completeTransaction(props.transactionToRedeem?.id)
          props.closeTremendousModal()
          break
        case 'ERROR':
          console.error('Error in tremendous iframe', data)
          props.closeTremendousModal()
          break
      }
    })
    return window.removeEventListener('message', () => {})
  }, [props.transactionToRedeem])

  useEffect(() => {
    if (!props.tremendousModalOpen) setLoading(true)
  }, [props.tremendousModalOpen])

  useEffect(() => {
    if (
      props.transactionToRedeem?.approval_required &&
      props.showApprovalWarning
    ) {
      setApprovalModalOpen(true)
      props.closeTremendousModal()
    }
  }, [props.transactionToRedeem?.approval_required])

  return (
    <>
      <Modal
        id="TremendousModal"
        open={props.tremendousModalOpen}
        className={`!p-0 ${
          !props.transactionToRedeem?.approval_required ? '!bg-transparent' : ''
        }`}
        hideCloseButton={!props.transactionToRedeem?.approval_required}
        onClose={props.closeTremendousModal}
        fullscreen
      >
        <div
          className="flex flex-col justify-center items-center h-full"
          onClick={props.closeTremendousModal}
        >
          {loading && <LoadingAnimation type="falling" className="absolute" />}
          <iframe
            id="tremendous-iframe"
            name="tremendous-iframe"
            onLoad={() => setLoading(false)}
            onClick={(e) => e.stopPropagation()}
            className="w-full md:max-w-[400px] h-[600px] bg-transparent rounded-2xl"
            src={props.transactionToRedeem?.reward_link || ''}
            sandbox="allow-same-origin allow-scripts allow-forms allow-modals allow-popups"
          />
          {!loading && props.transactionToRedeem?.approval_required && (
            <div className="p-2 w-full">
              <ModalButtons closeClick={() => setApprovalModalOpen(false)} />
            </div>
          )}
        </div>
      </Modal>
      <Modal
        id="approval-modal"
        open={approvalModalOpen}
        onClose={() => setApprovalModalOpen(false)}
        className="!max-w-lg"
      >
        <div className="flex flex-col items-center justify-center w-full h-full gap-4">
          <div className="self-start flex gap-4 flex-col">
            <div className="inline-flex gap-2">
              <InfoIcon className="text-themed-blue-500" />
              <Text variant="h2">Approval Required</Text>
            </div>
            <Text variant="body2">
              Your order is pending and requires approval.
            </Text>
          </div>
          <ModalButtons closeClick={() => setApprovalModalOpen(false)} />
        </div>
      </Modal>
    </>
  )
}

const ModalButtons = (props: { closeClick: (open: boolean) => void }) => (
  <div className="w-full flex gap-2">
    <Button
      id={'contact-support-button'}
      href="mailto:support@optiwatt.com"
      className="!p-2"
    >
      <MailIcon />
      Contact Support
    </Button>
    <Button
      id={'close-tremendous-modal-button'}
      onClick={() => props.closeClick(false)}
      variant="primary-subtle"
    >
      Close
    </Button>
  </div>
)

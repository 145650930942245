import { AlertMessage, ConfigurableButton } from '@/app/components'
import { Modal, Text } from '@/components'
import { useAppSelector } from '@/hooks'
import { Transaction } from '@/types/transaction'
import moment from 'moment'

const InvitationBonus = ({ transaction }: { transaction: Transaction }) => {
  return (
    <div className="flex flex-col items-center w-full h-full gap-4">
      <div className="w-full">
        <Text variant="h2">{'Invitation Bonus'}</Text>
      </div>
      <div className="flex flex-col items-center gap-4 h-full justify-center p-6">
        <Text variant="h1" className="text-themed-primary">{`+ ${Math.abs(
          transaction.points,
        )} pts`}</Text>
        <div className="w-full flex flex-col items-center">
          <Text variant="h3">{`For inviting ${transaction.referral.referred_name}`}</Text>
          <Text variant="h3-subheader" className="font-medium">
            {moment(transaction.created).format('MMM D, YYYY')}
          </Text>
        </div>
      </div>
    </div>
  )
}

const CashBack = ({ transaction }: { transaction: Transaction }) => {
  const user = useAppSelector((state) => state.user.user)
  return (
    <div className="flex flex-col items-center w-full gap-10 h-full justify-between">
      <div className="w-full">
        <Text variant="h2">{'Cash Back'}</Text>
      </div>
      <div className="flex flex-col items-center gap-4 md:gap-12 justify-center">
        <div className="flex flex-col items-center gap-4">
          <Text variant="h1" className="text-themed-primary">{`- ${Math.abs(
            transaction.points,
          )} pts`}</Text>
          <div className="w-full flex flex-col items-center">
            <Text variant="h3">{`Redeemed for $${
              Math.abs(transaction.points) / 100
            }`}</Text>
            <Text variant="h3-subheader" className="font-medium">
              {moment(transaction.created).format('MMM D, YYYY')}
            </Text>
          </div>
        </div>
        <AlertMessage variant="info">
          {`We will be sending your reward to your email that's on file`}
          <br />
          <br />
          {user?.username}
          <br />
          <br />
          {`Please allow 10-14 business days for your reward to arrive.`}
        </AlertMessage>
      </div>
      <div className="w-full">
        <ConfigurableButton
          id={'contactSupportButton'}
          variant="primary-subtle"
          onClick={() => window.open('https://optiwatt.com/#contact', '_blank')}
        >
          {'Contact Optiwatt Support'}
        </ConfigurableButton>
      </div>
    </div>
  )
}

export default function TransactionDisplayModal({
  transaction,
  onClose,
}: {
  transaction: Transaction | null
  onClose: () => void
}) {
  const transactionTypesRequiringModal: Transaction['type'][] = [
    'redeem',
    'referral',
  ]

  return (
    <Modal
      id="transactionDisplayModal"
      open={
        !!transaction &&
        transactionTypesRequiringModal.includes(transaction?.type)
      }
      onClose={onClose}
      className="md:!max-w-[400px] !w-full"
      fullscreen={window.innerWidth < 768}
    >
      {transaction?.type === 'redeem' ? (
        <CashBack transaction={transaction} />
      ) : transaction?.type === 'referral' ? (
        <InvitationBonus transaction={transaction} />
      ) : null}
    </Modal>
  )
}

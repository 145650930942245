import { ConfigurableButton } from '@/app/components'
import { Modal, Text } from '@/components'

export default function ReminderModal({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) {
  return (
    <Modal
      id="reminderModal"
      open={open}
      onClose={onClose}
      className="!max-w-[400px]"
    >
      <div className="flex flex-col items-center gap-8">
        <div className="w-full">
          <Text variant="h1">{`Reminder Sent`}</Text>
        </div>
        <div className="px-6">
          <Text variant="h3-subheader" className="text-center font-medium">
            {`We’ve reminded your friend to finish signing up. Remember, your friend
          needs to connect their Vehicle in order to receive your 500 point
          invitation bonus.`}
          </Text>
        </div>
        <ConfigurableButton
          id="reminderModalButton"
          variant="primary-subtle"
          onClick={onClose}
        >
          {`Got it`}
        </ConfigurableButton>
      </div>
    </Modal>
  )
}

import { Gift } from 'react-feather'
import { Text } from '@/components'
import InviteTab from './tabs/invite'
import ActivityTab from './tabs/activity'
import { ViewModelProps } from './useViewModel'
import Tabs from '@/components/Tabs/'
import { useHistory } from 'react-router-dom'
import { useUrlSearchParam } from '@/hooks'
import { useRef } from 'react'
import BrandedHeader from '@/app/components/NavigationPage/WhitelabeledNavHeader/BrandedHeader'

function Title() {
  return (
    <div className="flex items-center">
      <Gift className="w-6 mr-2 text-themed-primary" />
      <Text variant="h2">Rewards</Text>
    </div>
  )
}

export default function Rewards(props: ViewModelProps) {
  const tab = useUrlSearchParam('tab') ?? ''
  const history = useHistory()
  const tabLabels = ['Invite', 'Activity']
  const tabIndex = tabLabels.findIndex(
    (tabLabel) => tabLabel.toLocaleLowerCase() === tab.toLowerCase(),
  )
  const initialTabIndex = useRef(tabIndex !== -1 ? tabIndex : 1).current

  return (
    <div className="w-full flex justify-center px-5">
      <div className="flex flex-col w-full max-w-[600px]">
        <BrandedHeader />
        <Text variant="h2" className="mt-6">
          <Title />
        </Text>
        <div className="mt-6">
          <div className="flex flex-col gap-4 flex-grow">
            <Tabs.Tabs
              tabBarAttributes={{
                className: 'max-w-full',
              }}
              initialTabIndex={initialTabIndex}
              tabKey="reward-tabs"
              labels={tabLabels}
              onTabChange={(index) => {
                history.replace({
                  search: `tab=${tabLabels[index]}`,
                })
              }}
            />
            <Tabs.Content tabKey="reward-tabs">
              <InviteTab {...props} />
              <ActivityTab {...props} />
            </Tabs.Content>
          </div>
        </div>
      </div>
    </div>
  )
}
